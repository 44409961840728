import { apiStore, apiPost, generateFormData } from '../../modules/apiHelper';
import { isStaleData, now, invalideDate } from '../../modules/dataHelper';
import reportDefinitionObj from '../_helpers/reportDefinitionObj';
// import imageUrlToBase64 from '../_helpers/imageUrlToBase64';

export default {
  /**
   * get list of accounts by usertype and accountstate.
   *
   * @param {Object} context vuex
   * @param {Array} filters [
   *    accountType (mentor, mentee),
   *    accountState (verified{default}, dormant, unverified, suspended),
   *    companyId{Nullable}
   * ]
   */
  async getAccounts(context, [accountType, accountState = 'verified', companyId = '']) {
    if (!accountType) {
      console.error('account type not specified: ', this.arguments[1]);
      return [];
    }

    const apiStoreName = `acc/${accountState.toLowerCase()}/${accountType.toLowerCase()}/${companyId}/accounts`;
    const accounts = await apiStore.getItem(apiStoreName) || [invalideDate, []];
    const accountStateId = context.state.accountStates[accountState];
    const accountTypeId = context.rootState.User.accountTypes[accountType];
    const accountTypeProp = (accountType.toLowerCase() === 'mentee' || accountType.toLowerCase() === 'mentor')
      ? 'type'
      : 'level';

    //
    if (Number.isNaN(Number(accountStateId)) || Number.isNaN(Number(accountTypeId))) return false;

    const formData = generateFormData({
      state: accountStateId,
    });

    // xhr then update store
    const request = apiPost('accounts', formData)
      .catch((err) => {
        console.warn('getAccounts::accounts err', err);
        return false;
      })
      .then((res) => {
        if (!res) return false;
        if (res.data.error) {
          return false;
        }

        const accs = res.data.accounts.filter((acc) => (Number(acc[accountTypeProp]) === accountTypeId)
          // be linient on companyid, for the sake of depreciated accounts' datas
          && (Number.isNaN(Number(companyId))
            ? true
            : Number(companyId) === Number(acc.companyid)));

        //
        if (accs.length) {
          apiStore.setItem(apiStoreName, [now(), accs]);
        } else {
          apiStore.removeItem(apiStoreName);
        }

        return accs;
      });

    if (!isStaleData(accounts[0]) && accounts[1].length > 0) return accounts[1];

    // wait for the xhr
    const result = await request;
    return result;
  },

  /**
   * ! use report writer module worker
   */
  async downloadAccountsList(context, [accounts, reportName = 'Accounts']) {
    if (!accounts.length) return;

    // if a report is already being written, return
    const currentReportStatus = context.rootGetters.getReportStatus('downloadAccountsList');
    if (currentReportStatus) return;

    const doc = { ...reportDefinitionObj };

    // *
    // *
    // * METADATA
    // TODO: DYNAMIC AUTHOR DETAILS
    // const author = await context.dispatch('getUserData', context.rootState.User.id, { root: true }) || false;
    // const authorType = author
    //   \? Object.entries(context.rootState.User.accountTypes).find(([typeName, typeId]) => (Number(author.type) === typeId ? typeName : undefined))
    //   : undefined;
    //
    // author is the staff / admin
    // doc.info.author = author ? `${author.name}, [${authorType}]` : undefined;
    doc.info.title = reportName;


    // *
    // *
    // * HEADER
    doc.content.push({
      text: `${reportName}`,
      style: 'header',
    });


    // *
    // *
    // * BODY
    // ? photo, name, last_name, profession, email, working_num
    const tableHeaders = ['', 'Name', 'Profession', 'email', 'phone number'];
    const tableAccounts = {
      keys: ['', 'name', 'profession', 'email', 'working_num'],
      transformations: {
        name: (user) => `${user.name || ''} ${user.last_name || ''}`,
        profession: async (user) => {
          const d = await context.dispatch('getUserData', user.id, {
            root: true,
          });
          //
          return (d.profession || {}).profession
            || (d.menteeProfession || {}).profession
            || 'not set';
        },
      },
    };


    // ! transform data to pdf contents
    const tableBody = [];
    // const photoSize = [30, 30];
    const bodyPromises = [];

    accounts.forEach((account) => {
      const accountPromises = [];
      // console.log(account);
      const trow = Array(tableAccounts.keys).map(() => [{
        text: '',
      }]);


      // TODO: load Base64 account photo
      // trow[0]
      if (account.photo) {
        // const result = imageUrlToBase64(account.photo).then((image) => {
        //   console.log(image.length > 30, image);
        //   if (image.length > 30) {
        //     trow[0] = {
        //       image,
        //       // fit: photoSize,
        //       width: 80,
        //       // margin: 10,
        //     };
        //   }
        // });

        // accountPromises.push(result);
      }

      tableAccounts.keys.forEach(async (key, index) => {
        if (key) {
          let text = account[key] || '--';

          // console.log(typeof tableAccounts.transformations[key], key);
          if (typeof tableAccounts.transformations[key] === 'function') {
            const promise = tableAccounts.transformations[key](account);
            accountPromises.push(promise);
            text = await promise || '-----';
          }

          trow[index] = {
            text,
            style: doc.styles[key] ? key : 'table',
          };
        }
      });

      bodyPromises.push(Promise.all(accountPromises));
      Promise.all(accountPromises).then(() => {
        tableBody.push(trow);
      });
    });

    await Promise.all(bodyPromises);

    // ! compile the doc
    doc.content.push({
      columns: [
        {
          width: 14,
          text: '',
        },
        {
          width: '*',
          table: {
            headerRows: 1,
            body: [
              tableHeaders,
              ...tableBody,
            ],
          },
          layout: 'lightHorizontalLines',
        },
      ],
      pageBreak: 'after',
    });

    // console.log(doc);

    // ! write
    context.dispatch(
      'writeReport',
      {
        report: doc,
        reportType: 'downloadAccountsList',
        reportName,
      },
      { root: true },
    );
  },
};
