export default {
  state: () => ({
    accountStates: {
      unverified: 0,
      verified: 1,
      suspended: 2,
      dormant: 3,
    },
  }),
};
