export default {
  content: [],
  info: {},
  styles: {
    email: {
      fontSize: 11,
      bold: true,
      margin: [0, 12],
    },
    normal: {
      fontSize: 10,
    },
    table: {
      fontSize: 10,
      margin: [6, 12],
    },
    center: {
      alignment: 'justify',
    },
    header: {
      fontSize: 18,
      bold: true,
      margin: [0, 60, 0, 40],
    },
  },
};
